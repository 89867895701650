<template>
  <v-container>
    <v-card class="elevation-2 mx-auto" :loading="loading" max-width="600">
      <v-card-title>Email Configuration</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <form>
          <v-row>
            <v-col cols="10" md="10" sm="10" class="py-0 mt-4">
              <v-text-field
                label="Host"
                v-model="form_data.host"
                :error-messages="form_error.host"
                outlined
                dense
                autofocus
              ></v-text-field>
            </v-col>
            <v-col cols="2" md="2" sm="2" class="py-0 mt-4">
              <v-text-field
                label="Port"
                v-model="form_data.port"
                :error-messages="form_error.port"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6" sm="6" class="py-0">
              <v-text-field
                label="Username"
                v-model="form_data.username"
                :error-messages="form_error.username"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6" sm="6" class="py-0">
              <v-text-field
                :type="show1 ? 'text' : 'password'"
                label="Password"
                v-model="form_data.password"
                :error-messages="form_error.password"
                outlined
                dense
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show1 = !show1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field
                label="From Email"
                v-model="form_data.from"
                :error-messages="form_error.from"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field
                label="CC"
                v-model="form_data.cc"
                :error-messages="form_error.cc"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field
                label="BCC"
                v-model="form_data.bcc"
                :error-messages="form_error.bcc"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-text-field
                v-model="form_data.vendorRelatedEmailsBcc"
                label="BCC recipients of vendor-related emails"
                outlined
                dense
                :error-messages="form_error.vendorRelatedEmailsBcc"
                hint="Use comma to add multiple emails. for e.g. joe@mailinator.com,doi@mailinator.com"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn
            class="mr-4 mt-3"
            color="secondary"
            @click="updateEmailConfiguration"
            :loading="loading"
            :disabled="loading"
          >Update</v-btn>
        </form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "EditTemplates",
  data() {
    return {
      form_data: {},
      loading: false,
      form_error: {},
      show1: false,
    };
  },
  components: {
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    getEmailConfiguration() {
      let _self = this;
      _self.loading = true;
      this.$axios
        .get(`admin/email_configuration`)
        .then((res) => {
          _self.form_error = {};
          if (res.status) {
            let data = res.data.data;
            for (let key in data) {
              _self.form_data[key] = data[key];
            }
          } else {
            let errors = res.error;
            for (let key in errors) {
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    updateEmailConfiguration() {
      let _self = this;
      this.loading = true;
      this.$axios.put(`admin/email_configuration/edit`, this.form_data)
        .then((response) => {
          _self.form_error = {};
          if (response.data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            _self.$router.push("/email-configuration");
          } else {
            let errors = response.data.error;
            for (let key in errors) {
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
  },
   mounted() {
    this.getEmailConfiguration();
  },
};
</script>